import firebase from "firebase"

const getMedicines = async (data) => {
  let newData = []
  await Promise.all(
    data.map(async (doc, index) => {
      let medicineList = []
      let medicineDoc = await doc.medicinesRef.get() //perform this for every document in data
      medicineDoc.forEach((medicine) => {
        medicineList.push(medicine.data())
      })
      delete doc.medicinesRef
      newData.push({ ...doc, medicine: medicineList })
    })
  )

  return newData
}

export const getMHPConsultations = async ({ userEnrollment }) => {
  let data = []

  let filteredConsultations = await firebase
    .firestore()
    .collectionGroup("consultations")
    .where("mhpEmail", "==", userEnrollment.email)
    .get()

  filteredConsultations.forEach(async (doc) => {
    // let medTemp = await doc.ref.collection("medicines").get()

    let currentConsultation = {
      ...doc.data(),
      id: doc.id,
      medicinesRef: doc.ref.collection("medicines"),
    }

    if (currentConsultation?.userId) data.push(currentConsultation)
  })

  data = await getMedicines(data)

  return data
}
