import firebase from "firebase"

import {
  GATSBY_PATIENT_ROLE_ID,
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
} from "gatsby-env-variables"

export const getPatients = async ({ organizationId, userEnrollment }) => {
  let data = []
  let filteredPatients

  if (organizationId)
    switch (userEnrollment.role) {
      case GATSBY_MEDSTAFF_ROLE_ID:
        filteredPatients = await firebase
          .firestore()
          .collectionGroup("enrollments")
          .where("organizationId", "==", organizationId)
          .where("role", "==", GATSBY_PATIENT_ROLE_ID)
          .get()

        filteredPatients.forEach((doc) => {
          let parentId = doc.ref.path
            .split("users/")[1]
            .split("/enrollments")[0]

          data.push({
            enrollmentId: doc.id,
            id: parentId,
            ...doc.data(),
          })
        })

        break

      case GATSBY_MHP_ROLE_ID:
      default:
        filteredPatients = await firebase
          .firestore()
          .collectionGroup("enrollments")
          .where("organizationId", "==", organizationId)
          .where("role", "==", GATSBY_PATIENT_ROLE_ID)
          .get()

        filteredPatients.forEach((doc) => {
          let parentId = doc.ref.path
            .split("users/")[1]
            .split("/enrollments")[0]

          data.push({
            enrollmentId: doc.id,
            id: parentId,
            ...doc.data(),
          })
        })

        break
    }

  return data
}
