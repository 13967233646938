import firebase from "firebase"

import { getDocumentData } from "./getDocuments"

import { GATSBY_WEBSITE_URL } from "gatsby-env-variables"

export const getUserAddresses = async ({ addressesId }) => {
  let filteredAddress = await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesId || " ")
    .get()
  if (filteredAddress.data())
    filteredAddress = {
      ...filteredAddress.data(),
      id: filteredAddress.id,
    }
  else filteredAddress = {}

  return filteredAddress
}

export const getUserEnrollment = async ({ user }) => {
  let filteredDocuments = await firebase
    .firestore()
    .collection("users")
    .doc(user.id)
    .collection("enrollments")
    .where("subdomain", "==", GATSBY_WEBSITE_URL)
    .get()

  let data = { ...user }

  filteredDocuments.forEach((doc) => {
    data = { ...data, ...doc.data(), enrollmentId: doc.id }
  })

  return data
}
export const getUserData = async ({ authUid }) => {
  let userData = await getDocumentData({
    collectionName: "users",
    fieldName: "authUid",
    operation: "==",
    value: authUid,
    subCollectionName: "enrollments",
  })

  return userData
}
