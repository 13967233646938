import firebase from "firebase"

import { GATSBY_MEDSTAFF_ROLE_ID } from "gatsby-env-variables"
export const getMedstaffEnrollment = async ({ organizationAuthUid }) => {
  const filteredMedstaff = await firebase
    .firestore()
    .collectionGroup("enrollments")
    .where("organizationAuthUid", "==", organizationAuthUid)
    .get()

  let data = []
  filteredMedstaff.forEach((doc) => {
    if (doc.ref.path.split("users/")[1]) {
      let parentId = doc.ref.path.split("users/")[1].split("/enrollment")[0]
      data.push({ ...doc.data(), enrollmentId: doc.id, id: parentId })
    }
  })

  return data
}

export const getMedstaffUsers = async ({
  organizationAuthUid,
  userEnrollment,
  // enrollments,
}) => {
  let enrollments = []
  let updatedMedstaff = []

  if (userEnrollment.role === GATSBY_MEDSTAFF_ROLE_ID) {
    const filteredMedstaff = await firebase
      .firestore()
      .collectionGroup("enrollments")
      .where("organizationAuthUid", "==", organizationAuthUid)
      .get()

    filteredMedstaff.forEach((doc) => {
      if (doc.ref.path.split("users/")[1]) {
        let parentId = doc.ref.path.split("users/")[1].split("/enrollment")[0]
        enrollments.push({ ...doc.data(), enrollmentId: doc.id, id: parentId })
      }
    })

    for (var index = 0; index < enrollments.length; index++) {
      let enrollment = enrollments[index]

      let userDocument = await firebase
        .firestore()
        .collection("users")
        .doc(enrollment.id)
        .get()

      updatedMedstaff.push({
        ...userDocument.data(),
        ...enrollment,
        id: userDocument.id,
      })
    }
  }

  return updatedMedstaff
}
