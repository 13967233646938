import { getDocumentData } from "./getDocuments"
export const getOrganization = async ({ authUid }) => {
  if (authUid) {
    let organization = await getDocumentData({
      collectionName: "organizations",
      fieldName: "authUid",
      operation: "==",
      value: authUid,
    })

    return organization
  } else return {}
}
