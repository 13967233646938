import axios from "axios"

import { flattenLabelValueObject } from "../../../../services/general"

import { GATSBY_FIRESTORE_FIREBASE_FUNCTION_URL } from "gatsby-env-variables"

export const getPatientsFromConsults = async ({ consultations }) => {
  consultations = consultations.filter((consultation) => consultation.userId)

  let filteredConsultations = [
    ...new Set(consultations.map((consult) => `users/${consult.userId}`)),
  ]

  let results

  if (filteredConsultations.length) {
    results = await axios(GATSBY_FIRESTORE_FIREBASE_FUNCTION_URL + "/getAll", {
      method: "post",
      data: filteredConsultations,
    })

    results.data.forEach((consultation) => {
      if (consultation !== null)
        consultation = flattenLabelValueObject(consultation)
    })

    // filter nulls
    results.data = results.data.filter((consultations) => consultations)
  } else results = {}

  return results.data
}
