import firebase from "firebase"

export const getMedstaffConsultations = async ({
  organizationId,
  patients,
}) => {
  let data = []
  const filteredConsultations = await firebase
    .firestore()
    .collectionGroup("consultations")
    .where("organizationId", "==", organizationId)
    .get()

  filteredConsultations.forEach(async (consultation) => {
    let medicineList = []
    let medicineDoc = await consultation.ref.collection("medicines").get()

    medicineDoc.forEach((medicine) => {
      medicineList.push(medicine.data())
    })

    let currentConsultation = {
      patient:
        patients[
          patients.findIndex(
            (patient) => patient.id === consultation.data().userId
          )
        ],
      medicine: medicineList,
      ...consultation.data(),
    }

    data.push(currentConsultation)
  })

  return data
}
