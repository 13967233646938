import React from "react"
import firebase from "firebase"
import { navigate, Link } from "gatsby"

import { handleSignOut } from "./signout"
import { isBrowser, isObjectEmpty } from "services/general"
import moment from "moment"

import VerifyEmailModal from "../VerifyEmailModal"

import {
  GATSBY_ENV,
  GATSBY_MEDSTAFF_ROLE_ID,
  GATSBY_MHP_ROLE_ID,
} from "gatsby-env-variables"

import { getPatients } from "./queries/getPatients"
import { getUserData, getUserEnrollment } from "./queries/getUser"
import { getMedstaffUsers } from "./queries/getMedstaff"
import { getMHPConsultations } from "./queries/getMHPConsultations"
import { getMedstaffConsultations } from "./queries/getMedstaffConsultations"
import { getOrganization } from "./queries/getOrganizations"
import { getPatientsFromConsults } from "./queries/getPatientsFromConsults"

const getFilteredConsultations = (consultations) => {
  const DAYS_BUFFER = 14

  return consultations.filter((consultation) => {
    let earliestConsultDateToShow = moment().add(-DAYS_BUFFER, "days")
    let latestConsultDateToShow = moment().add(DAYS_BUFFER, "days")

    let validConsultStatus = [
      "tentative",
      "confirmed",
      "completed",
      "done",
      "no show",
    ]

    return (
      validConsultStatus.includes(consultation?.consultStatus?.toLowerCase()) &&
      !!consultation?.startDateTime &&
      moment(consultation?.startDateTime).isAfter(earliestConsultDateToShow) &&
      moment(consultation?.startDateTime).isBefore(latestConsultDateToShow)
    )
  })
}

export const verifyUser = async ({ userData }) => {
  let roles = userData?.roles

  let updatedRoles = []

  //Update only if a role was updated
  if (updatedRoles.length > 0)
    await firebase
      .firestore()
      .collection("users")
      .doc(userData.id)
      .update({
        ...userData,
        roles,
      })

  return {
    ...userData,
    roles,
  }
}

export const handleSigninNavigate = ({ organization }) => {
  switch (true) {
    case !isObjectEmpty(organization):
      navigate("/profile")
      break
    default:
      navigate("/register")

      break
  }
}

export const handleEmailLogin = async ({
  values,
  setMessage,
  errorCallback,
  dispatch,
  setLoading,
  location,
}) => {
  let email = values?.email
  let password = values?.password

  try {
    // Sign in user
    let response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    // const isVerified =
    //   GATSBY_ENV === "production" ? response?.user?.emailVerified : true

    const isVerified = true

    if (isVerified) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      const authUid = response?.user?.uid

      let userData = await getUserData({ authUid })

      let userEnrollment = await getUserEnrollment({ user: userData })

      let organization = await getOrganization({
        authUid: authUid,
      })

      let patients, consultations

      switch (userEnrollment?.role) {
        case GATSBY_MEDSTAFF_ROLE_ID:
          patients = await getPatients({
            organizationId: organization?.id,
            userEnrollment,
          })
          consultations = await getMedstaffConsultations({
            organizationId: organization?.id,
            patients,
            userEnrollment,
          })

          break

        case GATSBY_MHP_ROLE_ID:
        default:
          consultations = await getMHPConsultations({
            userEnrollment,
          })

          patients = await getPatientsFromConsults({
            consultations,
          })

          break
      }

      let medstaffUsers = await getMedstaffUsers({
        organizationAuthUid: authUid,
        userEnrollment,
      })

      userData = await verifyUser({ organization, userData })

      if (!patients) patients = []

      // Save user data and addresses to session
      if (isBrowser()) {
        let filteredConsultations = getFilteredConsultations(consultations)

        sessionStorage.setItem(
          "userData",
          JSON.stringify({ ...userEnrollment })
        )
        sessionStorage.setItem("organization", JSON.stringify(organization))
        sessionStorage.setItem("medstaff", JSON.stringify(medstaffUsers))
        sessionStorage.setItem("patients", JSON.stringify(patients))
        sessionStorage.setItem(
          "consultations",
          JSON.stringify(filteredConsultations)
        )
      }

      //TO DO: has to handle if the account has already registered an organization (if yes, go to profile)
      if (userEnrollment.requireDeputy) {
        navigate("/deputy/employees")
      } else {
        navigate("/schedule")
      }
      // navigate("/register/mechanics")
    } else {
      // If not verified, prevent login and show modal
      handleSignOut({ redirect: false })
      setLoading(false)
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Your email is not verified",
          isCard: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
          content: (
            <VerifyEmailModal user={response?.user} location={location} />
          ),
        },
      })
      return
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    switch (error?.code) {
      case "auth/wrong-password":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message:
              "Invalid email or password. Please check if your credentials are correct before logging in again.",
          },
        })
        break

      case "auth/user-not-found":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: (
              <span>
                The email you entered does not match our records. Please{" "}
                <Link to="/verify-email">create an account</Link> to avail of
                our services.
              </span>
            ),
          },
        })
        break

      default:
        setMessage({
          type: "danger",
          content: { code: error.code, message: error.message },
        })
    }
  }
}
