import firebase from "firebase"

export const getDocumentData = async ({
  collectionName,
  fieldName,
  value,
  operation = "==",
}) => {
  const filteredDocuments = await firebase
    .firestore()
    .collection(collectionName)
    .where(fieldName, operation, value)
    .get()
  let data
  filteredDocuments.forEach((doc) => {
    data = { ...doc.data(), id: doc?.id }
  })

  if (data) {
    return data
  } else {
    return {}
  }
}
